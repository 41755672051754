import { Injectable, Inject } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/internal/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../shared/components/spinner/spinner';
import { LoadingService } from '../shared/services/loading.service';
import { LocalStorageService } from '../services/local-storage.service';
import { NOTYF } from '../shared/utils/notyf.token';
import { Notyf } from 'notyf';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  active: SpinnerComponent;
  counter = 0;
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private spinner: LoadingService,
    @Inject(NOTYF) private notyf: Notyf
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const url = this.getUrl(request.url);
    let authToken = this.localStorageService.getAuthToken();
    let showSpinner = true;
    if(url.indexOf("googleapis")>0){
      authToken=null;
    }

    request = request.clone({ url });
    if (request.headers.has('hideSpinner')) {
      request.headers.delete('hideSpinner');
      showSpinner = false;
    }
    if (!request.headers.has('Content-Type')) {
      request.headers.append('Content-Type', 'application/json');
    }
    const success = request.headers.get('successToaster');
    request.headers.delete('successToaster');
    if (authToken) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authToken
        }
      });
    }
    return next.handle(request).pipe(
      tap((evt: HttpEvent<any>) => {
        if (evt.type === HttpEventType.Sent) {
          if (showSpinner) {
            this.start();
          }
        } else if (evt.type === HttpEventType.Response) {
          this.complete(success);
        }
      }, this.handleError.bind(this))
    );
  }

  handleError(error: HttpResponse<any>): Observable<void> {
    if (error instanceof HttpErrorResponse) {
      this.complete();
      if (error.status === 400) {
        if (error.error.hasOwnProperty('message')) {
          const message=(error.error.message.includes("The given data was invalid")?"Please review all the fields for errors before submitting":error.error.message.trim());
          this.alert(message);
        }
        if (
          Object.keys(error.error).findIndex(
            key =>
              error.error[key].findIndex((errorMsg: string) => {
                return errorMsg.search('required') !== -1;
              }) !== -1
          ) !== -1
        ) {
          this.alert('Please fill out all the fields in the form');
        }
      }
      if (error.status === 401) {
        if (error.error.hasOwnProperty('message')) {
          this.alert(error.error.message);
        }
      }
      if (error.status === 422) {

        if (error.error && error.error.message && error.error.errors && error.error.errors.applicationId) {
          const message = error.error.errors.applicationId.join(' ');
          this.alert(message);
          
        }
      }
      const isAuthenticationError = error.status === 401;
      const url = this.router.routerState.snapshot.url;
      const isLoginPage = url.includes('login');
      const isRoleError = error.status === 403;
      if (!isLoginPage && isAuthenticationError) {
        this.localStorageService.deleteAuthToken();
        this.router.navigate(['/']);
      } else if (isRoleError) {
        this.router.navigate(['403']);
      }
    }
    return throwError(error);
  }

  private getUrl(url: string): string {
    let mUrl=`${environment.apiUrl}${url}`;
    if(url.indexOf("googleapis")>0){
      mUrl=url;
    }
    return mUrl;
  }

  private start() {
    this.counter += 1;
    if (this.counter === 1) {
      this.active = this.spinner.showLoader();
    }
  }

  private complete(successToaster?: string) {
    if (successToaster) {
      this.success(successToaster);
    }
    if (this.counter > 0) {
      this.counter -= 1;
    }

    if (this.counter === 0) {
      if (this.active) {
        this.active.hide();
      }
    }
  }

  alert(error: string) {
    this.notyf.error(error);
  }

  success(success: string) {
    this.notyf.success(success);
  }

  warning() {
    this.notyf.open({
      type: 'warning',
      message: 'Please fill out all the fields in the form'
    });
  }
}
