import { Component, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { LocalStorageService } from '@services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {

  showMenu = false;
  showAddMenu = false;
  listener: any;

  @Input() options = [];

  constructor(
    private localStorage: LocalStorageService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) { }

  menuToggle(): void {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.listener = this.renderer.listen('body', 'click', event => {
        if (
          event.target.className !== 'top-navbar-submenu' &&
          event.target.className !== 'caret-image'
        ) {
          this.showMenu = false;
          this.cdr.markForCheck();
        }
      });
    } else {
      this.listener.call();
    }
  }

  addMenuToggle(): void {
    this.showAddMenu = !this.showAddMenu;
    if (this.showAddMenu) {
      this.listener = this.renderer.listen('body', 'click', event => {
        if (
          event.target.className !== 'add-menu' &&
          event.target.className !== 'add-menu-text' &&
          event.target.className !== 'caret' &&
          event.target.className !== 'top-navbar-submenu'
        ) {
          this.showAddMenu = false;
          this.cdr.markForCheck();
        }
      });
    } else {
      this.listener.call();
    }
  }

  onLogout(): void {
    this.localStorage.deleteAuthToken();
    this.localStorage.deleteUserInfo();
  }

  get id(): string {
    return this.localStorage.getUserInfo().id;
  }

  get companyName(): string {
    return this.localStorage.getUserInfo().companyName;
  }

  get email(): string {
    return this.localStorage.getUserInfo().email;
  }

  get isAdmin(): boolean {
    return this.localStorage.getUserInfo().role  === 'admin';
  }
}
