import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalTemplateDirective } from './modal-template.directive';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.html',
  styleUrls: ['./modal.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: { class: 'modal' },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('modalAnimation', [
      state('in', style({ opacity: '1' })),
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate('1s ease-out')
      ]),
      transition('* => void', [
        style({ height: '*' }),
        animate('1s ease-out', style({
          opacity: 0,
        }))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
  set destroyFn(fn: Function) {
    this._destroyFn = fn;
  }

  @ViewChild(ModalTemplateDirective)
  modalTemplate: ModalTemplateDirective;

  @ViewChild('modalContent')
  modalContent: ElementRef;

  data: any;
  block = false;

  onClose: Subject<any> = new Subject<any>();
  private _destroyFn: Function;
  private elWithFocus: HTMLElement;

  constructor(private cdr: ChangeDetectorRef) {}

  close(reason?: string) {
    requestAnimationFrame(() => {
      this.onClose.next(reason);
      this._destroyFn.call(undefined);
    });
  }

  onClick() {
    if (!this.block) {
      this.close(null);
    }
  }

  ngOnInit() {
    this.elWithFocus = document.activeElement as HTMLElement;
  }

  ngAfterViewInit() {
    this.modalContent.nativeElement.focus();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    const body = document.body;
    const elWithFocus = this.elWithFocus;

    let elementToFocus;
    if (elWithFocus && elWithFocus.focus && body.contains(elWithFocus)) {
      elementToFocus = elWithFocus;
    } else {
      elementToFocus = body;
    }
    elementToFocus.focus();
    this.elWithFocus = null;
  }
}
