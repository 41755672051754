import { Injectable } from '@angular/core';

enum LocalStorageEnum {
  AUTH_TOKEN = 'auth_token',
  USER_ID = 'user_id',
  USER_ROLE = 'user_role',
  USER_ROLE_ID = 'user_role_id',
  USER_INDUSTRY = 'user_industry',
  USER_COMPANY_NAME = 'user_company_name',
  USER_EMAIL = 'user_email',
  RATE = 'can_rate',
  PROUDLY = 'proudly_code',
  ORGANIZATION = "organization",  
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public deleteAuthToken(): void {
    localStorage.removeItem(LocalStorageEnum.AUTH_TOKEN);
  }

  public getAuthToken(): string {
    return localStorage.getItem(LocalStorageEnum.AUTH_TOKEN);
  }

  public getOrganization(): string {
    return localStorage.getItem(LocalStorageEnum.ORGANIZATION);
  }

  public setProudly(value: string): void {
    localStorage.setItem(LocalStorageEnum.PROUDLY, value);
  }
  
  public setAuthToken(value: string): void {
    localStorage.setItem(LocalStorageEnum.AUTH_TOKEN, value);
  }

  public setOrganization(value: string): void {
    localStorage.setItem(LocalStorageEnum.ORGANIZATION, value);
  }

  setUserId(id: number): void {
    localStorage.setItem(LocalStorageEnum.USER_ID, id.toString());
  }

  setRole(role: string): void {
    localStorage.setItem(LocalStorageEnum.USER_ROLE, role);
  }

  setIndustry(industry: number): void {
    localStorage.setItem(LocalStorageEnum.USER_INDUSTRY, industry.toString());
  }

  setRate(rate: string): void {
    localStorage.setItem(LocalStorageEnum.RATE, rate);
  }

  

  canRate(): boolean {
    return localStorage.getItem(LocalStorageEnum.RATE) === 'true';
  }

  deleteRate(): void {
    localStorage.removeItem(LocalStorageEnum.RATE);
  }

  public setUserInfo(id: number, companyName: string, email: string, organization: string): void {
    localStorage.setItem(LocalStorageEnum.USER_COMPANY_NAME, companyName);
    localStorage.setItem(LocalStorageEnum.ORGANIZATION, organization);
    localStorage.setItem(LocalStorageEnum.USER_EMAIL, email);
    localStorage.setItem(LocalStorageEnum.USER_ROLE_ID, id.toString());
  }

  public getUserInfo(): any {
    return {
      userId: localStorage.getItem(LocalStorageEnum.USER_ID),
      id: parseInt(localStorage.getItem(LocalStorageEnum.USER_ROLE_ID), 10),
      role: localStorage.getItem(LocalStorageEnum.USER_ROLE),
      industry: localStorage.getItem(LocalStorageEnum.USER_INDUSTRY),
      email: localStorage.getItem(LocalStorageEnum.USER_EMAIL),
      companyName: localStorage.getItem(LocalStorageEnum.USER_COMPANY_NAME),
      proudly: localStorage.getItem(LocalStorageEnum.PROUDLY),
      organization: localStorage.getItem(LocalStorageEnum.ORGANIZATION)
    };
  }

  public deleteUserInfo(): void {
    localStorage.removeItem(LocalStorageEnum.USER_ID);
    localStorage.removeItem(LocalStorageEnum.USER_ROLE_ID);
    localStorage.removeItem(LocalStorageEnum.USER_ROLE);
    localStorage.removeItem(LocalStorageEnum.USER_COMPANY_NAME);
    localStorage.removeItem(LocalStorageEnum.ORGANIZATION);
    localStorage.removeItem(LocalStorageEnum.USER_EMAIL);
    localStorage.removeItem(LocalStorageEnum.USER_INDUSTRY);
  }
}
