import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  Inject,
  TemplateRef
} from '@angular/core';

import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';
import { ModalComponent } from '../components/modal/modal';

export class ModalRef {
  constructor(
    public onClose: Subject<any>,
    public instance: any
  ) {}
}

export class ActiveModal {
  data: any;
  close(result?: any) {}
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private contentPortal: ComponentPortal<any>;
  private bodyPortalHost: DomPortalHost;
  private modalInstance: ModalComponent;

  constructor(
    @Inject(DOCUMENT) protected document: any,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}


  public open(content: any, data: any, block: boolean = false): ModalRef {
    this.modalInstance = this.createModalInstance();
    this.modalInstance.data = data;
    this.modalInstance.block = block;

    const activeModal = this.createActiveModalInstance(this.modalInstance);
    let componentInstance;
    if (content instanceof TemplateRef) {
      this.createTemplateModal(this.modalInstance, activeModal, content);
    } else {
      componentInstance = this.createComponentModal(
        this.modalInstance,
        activeModal,
        content
      );
    }
    const contentPortal = this.contentPortal;
    this.modalInstance.destroyFn = () => {
      if (contentPortal.isAttached) {
        contentPortal.detach();
      }
    };

    return new ModalRef(this.modalInstance.onClose, componentInstance);
  }

  createModalInstance(): ModalComponent {
    this.contentPortal = new ComponentPortal(ModalComponent);
    this.bodyPortalHost = new DomPortalHost(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    const componentRef = this.contentPortal.attach(this.bodyPortalHost);
    return componentRef.instance;
  }

  createActiveModalInstance(modalInstance: ModalComponent): ActiveModal {
    const activeModal = new ActiveModal();
    activeModal.close = modalInstance.close.bind(modalInstance);
    activeModal.data = modalInstance.data;
    return activeModal;
  }


  createComponentModal(
    modalInstance: ModalComponent,
    activeModal: ActiveModal,
    content: any
  ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      content
    );

    const modalInjector = Injector.create({
      providers: [
        {
          provide: ActiveModal,
          useValue: activeModal
        }
      ],
      parent: this.injector
    });

    return modalInstance.modalTemplate.vcr.createComponent(
      componentFactory,
      undefined,
      modalInjector
    ).instance;
  }

  createTemplateModal(
    dfModalInstance: ModalComponent,
    activeModal: ActiveModal,
    content: TemplateRef<any>
  ) {
    const view = dfModalInstance.modalTemplate.vcr.createEmbeddedView(content);

    // set the context of the templateRef so that the user can do
    // let-close="close" and let-data="data"
    Object.assign(view.context, activeModal);
  }

}
