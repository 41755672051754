import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  OnInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => ToggleComponent),
  multi: true
};

@Component({
  selector: 'app-toggle',
  templateUrl: 'toggle.component.html',
  styleUrls: ['toggle.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ToggleComponent<T = any> implements ControlValueAccessor, OnInit {
  private onChangeCallback: (_: any) => void = noop;
  private _selectedItem: T;
  private _selectedIndex: number;

  @Input() items: { label: string; value: T }[];

  get selectedItem() {
    return this._selectedItem;
  }

  set selectedItem(value: T) {
    if (value !== this._selectedItem) {
      this._selectedItem = value;
      this.onChangeCallback(value);
      this.change.emit(this.selectedItem);
    }
  }

  private _disabled = false;

  @Input()
  get disabled() {
    return this._disabled;
  }

  

  set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input()
  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(value: number) {
    this._selectedIndex = value;
    this.setItemByIndex();
  }

  private _default;

  @Input()
  set default(value: T) {
    this._default = value;
    this.selectItem(value);
  }

  get default(): T {
    return this._default;
  }

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (!isNullOrUndefined(this.default)) {
      this.setDefaultSelection();
    }
  }

  validateInputs() {
    if (this.selectedIndex >= this.items.length) {
      console.error('SELECTED_INDEX_NOT_FOUND');
      return false;
    }
    return true;
  }

  selectItem(value: T) {
    const index = this.items.findIndex(el => el.value === value);
    if (index > -1) {
      this.selectedItem = value;
      this._selectedIndex = index;
    } else {
      console.error('SELECTED_ITEM_NOT_FOUND');
    }
  }

  isItemSelected(item: { label: string; value: T }) {
    return this.selectedItem >= item.value;
  }

  writeValue(value: T | T[]) {
    if (!isNullOrUndefined(value)) {
      if (value !== this._selectedItem) {
        this._selectedItem = value as T;
        this.selectItem(value as T);
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {}

  private setDefaultSelection() {
    if (isNullOrUndefined(this.selectedIndex)) {
      this.selectedIndex = 0;
    }
  }

  private setItemByIndex() {
    this.selectedItem = this.items[this._selectedIndex].value;
    if (!this.validateInputs()) {
      this.selectedItem = null;
    }
  }
}

export function isNullOrUndefined<T>(
  value: T | null | undefined
): value is null | undefined {
  return typeof value === 'undefined' || value === null;
}
