import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class LocationService {
  API_KEY="AIzaSyAdqgygIEEEsK35RJhMqtsCECv9DQXNh38";

  constructor(private http: HttpClient) {}

  getLocation(address: string): Observable<any> {
    const URL="https://maps.googleapis.com/maps/api/geocode/json?sensor=false&key="+this.API_KEY+"&address="+address;
    return this.http.get(URL);
  }

}
