import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './components/table/table.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import {
  NgbRatingModule,
  NgbAccordionModule,
  NgbTypeaheadModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FilterComponent } from './components/filter/filter.component';
import { SpinnerComponent } from './components/spinner/spinner';
import { SuccessfulComponent } from './entry-components/successful/successful.component';
import { AcceptComponent } from './entry-components/accept/accept.component';
import { ModalComponent } from './components/modal/modal';
import { ActiveModal } from './services/modal.service';
import { ModalTemplateDirective } from './components/modal/modal-template.directive';
import { SecondaryLayoutComponent } from './components/secondary-layout/secondary-layout.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ExperienceComponent } from './components/experience/experience.component';
import { ProfileComponent } from './entry-components/profile/profile.component';
import { SubproductComponent } from './components/subproduct/subproduct.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SidebarComponent,
    TableComponent,
    ToggleComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    MainLayoutComponent,
    FilterComponent,
    SpinnerComponent,
    AcceptComponent,
    SuccessfulComponent,
    ModalComponent,
    ModalTemplateDirective,
    ExperienceComponent,
    SecondaryLayoutComponent,
    ProfileComponent,
    SubproductComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbRatingModule,
    NgbAccordionModule,
    NgMultiSelectDropDownModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    FormsModule
  ],
  exports: [
    SidebarComponent,
    FooterComponent,
    NavbarComponent,
    FilterComponent,
    TableComponent,
    ToggleComponent,
    MainLayoutComponent,
    SecondaryLayoutComponent,
    NgbRatingModule,
    NgbAccordionModule,
    SpinnerComponent,
    ExperienceComponent,
    NgMultiSelectDropDownModule,
    NgbTypeaheadModule,
    SubproductComponent
  ],
  providers: [ActiveModal],
  entryComponents: [
    SpinnerComponent,
    ModalComponent,
    AcceptComponent,
    SuccessfulComponent,
    ProfileComponent
  ]
})
export class SharedModule {}
