import { Component } from '@angular/core';
import {ActiveModal} from '../../services/modal.service';

@Component({
  selector: 'app-successful',
  templateUrl: './successful.component.html',
  styleUrls: ['./successful.component.scss']
})
export class SuccessfulComponent {
  constructor(private activeModal: ActiveModal) {}

  close(reason: string): void {
    this.activeModal.close(reason);
  }
}
