import { InjectionToken } from '@angular/core';
import { Notyf } from 'notyf';

export const NOTYF = new InjectionToken<Notyf>('NotyfToken');

export function notyfFactory(): Notyf {
  return new Notyf({
    ripple: false,
    duration: 3000,
    types: [
        {
          type: 'warning',
          icon: {
            className: 'warning-icon',
            tagName: 'i',
          }
        }
      ]
  });
}
