export const ROUTES = {
  admin: {
    permissions: {
      admin: true,
      member: false,
      supplier: false
    },
    add: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    },
    profile: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    }
  },
  members: {
    add: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    },
    profile: {
      permissions: {
        admin: true,
        member: true,
        supplier: false
      }
    },
    refer: {
      permissions: {
        admin: false,
        member: true,
        supplier: false
      }
    },
    manage: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    }
  },
  suppliers: {
    add: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    },
    profile: {
      permissions: {
        admin: false,
        member: false,
        supplier: true
      }
    },

    ratings: {
      permissions: {
        admin: false,
        member: false,
        supplier: true
      }
    },
    refer: {
      permissions: {
        admin: false,
        member: true,
        supplier: false
      }
    },
    manage: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    },
    search: {
      permissions: {
        admin: false,
        member: true,
        supplier: false
      }
    }
  },
  dashboard: {
    permissions: {
      admin: true,
      member: true,
      supplier: true
    }
  },
  messages: {
    permissions: {
      admin: false,
      member: true,
      supplier: true
    }
  },
  opportunities: {
    publish: {
      permissions: {
        admin: false,
        member: true,
        supplier: false
      }
    },
    home: {
      permissions: {
        admin: false,
        member: true,
        supplier: true
      }
    },
    search: {
      permissions: {
        admin: false,
        member: false,
        supplier: true
      }
    },
    list: {
      permissions: {
        admin: false,
        member: true,
        supplier: false
      }
    }
  },
  rate: {
    permissions: {
      admin: false,
      member: true,
      supplier: false
    }
  },
  'suppliers-rate': {
    permissions: {
      admin: false,
      member: true,
      supplier: false
    }
  },

  rates: {
    permissions: {
      admin: true,
      member: false,
      supplier: false
    }
  },
  searches: {
    permissions: {
      admin: true,
      member: false,
      supplier: false
    }
  },
  'pending-changes': {
    permissions: {
      admin: true,
      member: false,
      supplier: false
    }
  },
  contact: {
    message: {
      permissions: {
        admin: false,
        member: true,
        supplier: true
      }
    },
    list: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    },
    detail: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    }
  },
  about: {
    permissions: {
      admin: true,
      member: true,
      supplier: true
    }
  },
  terms: {
    permissions: {
      admin: true,
      member: true,
      supplier: true
    }
  },
  faqs: {
    add: {
      permissions: {
        admin: true,
        member: false,
        supplier: false
      }
    }
  },
  'calendar-event': {
    permissions: {
      admin: true,
      member: true,
      supplier: true
    }
  },
  'ticket': {
    permissions: {
      admin: true,
      member: true,
      supplier: true
    }
  },
  'commitment-list': {
    permissions: {
      admin: true,
      member: false,
      supplier: false
    }
  },
};

export const HOMELINKS = {
  member: 'suppliers/search',
  supplier: 'opportunities/search',
  admin: 'suppliers/manage'
};

export const PROFILELINKS = {
  member: 'members/profile',
  supplier: 'suppliers/profile',
  admin: 'admin/profile'
};



export const DASHBOARDLINKS = {
  member: 'dashboard/members',
  supplier: 'dashboard/suppliers',
  admin: 'dashboard/admin'
};

export function checkLink(url: string, role: string): boolean {
  const segments = url.split('/').filter(el => el !== '');
  const length = segments.length;
  let current = ROUTES[segments[0]];
  let i = 0;
  while (current && i < length) {
    if (current.hasOwnProperty('permissions')) {
      if (current.hasOwnProperty('permissions') && current.permissions[role]) {
        return true;
      } else {
        return false;
      }
    } else {
      i++;
      current = current[segments[i]];
    }
  }
  return true;
}
