import {
  Component,
  ViewEncapsulation,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  Renderer2,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: 'spinner.html',
  styleUrls: ['spinner.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'spinner'
  }
})
export class SpinnerComponent {
  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  constructor(
    public renderer: Renderer2,
    public changeDetector: ChangeDetectorRef
  ) {}

  hide(): void {
    this.cancel.emit();
  }
}
