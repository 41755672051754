import { Component, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { TableAction, TableColumn } from '@shared/models';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  @Input() columns: TableColumn[] = [];
  @Input() collectionSize: number;
  @Input() key: string;
  
  @Input() 
  set totalPages(value: number) {
    this._totalPages = value;
    this.calculatePages();
  }
  get totalPages(): number {
    return this._totalPages;
  }

  @Input() currentPage = 1;
  @Input() 
  set data(value: any[]) {
    //remove null from _data check if value not null
    if (value && value.length > 0) {
       this._data = value.filter(el => el !== null);

    } else {
      this._data = [];
    }
    
  
  }
  get data(): any[] { 
    return this._data;
  }

  @Input() currentSort: any;

  @Output() sort: EventEmitter<any> = new EventEmitter();
  @Output() actionClick: EventEmitter<TableAction<any>> = new EventEmitter();
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter();

  stacked = false;
  destroy$: Subject<void> = new Subject();
  pages: (number | string)[] = [];
  private _totalPages = 1;
  public _data: any[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
  ) {
    this.breakpointObserver
      .observe('(max-width: 767px)')
      .pipe(
        map(result => result.matches),
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(el => {
        this.stacked = el;
        this.cdr.markForCheck();
      });
  }

  calculatePages(): void {
    const pageCount = 5; // Number of pages to show around the current page
    const pages = [];
    const half = Math.floor(pageCount / 2);

    let start = Math.max(2, this.currentPage - half);
    let end = Math.min(this.totalPages - 1, this.currentPage + half);

    // Adjust start and end when current page is near the boundaries
    if (this.currentPage <= half) {
        start = 2;
        end = Math.min(this.totalPages - 1, pageCount);
    }

    if (this.currentPage + half >= this.totalPages - 1) {
        start = Math.max(2, this.totalPages - pageCount + 1);
        end = this.totalPages - 1;
    }

    // Add the first page
    pages.push(1);

    // Add ellipsis if needed
    if (start > 2) {
        pages.push('...');
    }

    // Add the page numbers
    for (let i = start; i <= end; i++) {
        pages.push(i);
    }

    // Add ellipsis if needed
    if (end < this.totalPages - 1) {
        pages.push('...');
    }

    // Add the last page if totalPages > 1
    if (this.totalPages > 1) {
        pages.push(this.totalPages);
    }

    // Assign the pages to the component variable
    this.pages = pages;
}


  goToPage(page: number): void {
    if (page !== this.currentPage && typeof page === 'number') {
      this.currentPageChange.emit(page);
      this.currentPage = page;
      this.calculatePages();
    }
  }

  onApproveChange(row: any, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    row.approved = inputElement.checked;
  
    // Optionally, emit the change or handle it according to your logic
    // this.actionClick.emit({ field: 'approved', value: row });
    console.log('Approved status changed:', row);
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  onSort(field: string, order: 'asc' | 'desc'): void {
    this.sort.emit({ field, order });
  }

  isActive(field: string, order: 'asc' | 'desc'): boolean {
    return this.currentSort && field === this.currentSort.field && order === this.currentSort.order;
  }

  actionClass(field: string, value: string): string {
    return `table__cell-data--action--${field} table__cell-data--action--${field}--${value}`;
  }

  onActionClick(field: string, value: any): void {
    if (field === "delete" || field === "active-user") {
      this.actionClick.emit({ field, value });
    } else {
      this.actionClick.emit({ field, value: this.key ? value[this.key] : value });
    }
  }

  isProudly(value: any): boolean {
    return value.proudly_code != null && value.proudly_code !== "";
  }

  isMou(value: any): boolean {
    return value.mou_code != null && value.mou_code !== "";
  }

  isSabs(value: any): boolean {
    return value.sasb_code != null && value.sasb_code !== "";
  }

  isOld(value: any): boolean {
    return value.isOld != null && value.isOld !== "" || value.isOld;
  }

//  isActiveUser(value: any, type: 'bool' | 'text'): boolean | string {
//   console.log("test this", value, type);
//   const status = value.isActive === true; // Explicitly check for boolean true
//   return type === 'bool' ? status : (status ? 'Active' : 'Inactive');
// }

isActiveUser(value: any, type: 'bool' | 'text'): boolean | string {
  console.log("test this", value, type);
  const status = value.isActive === true; // Explicitly check for boolean true
  return type === 'bool' ? status : (status ? 'Active' : 'Inactive');
}

  isIcon(type: string): boolean {
    return type.indexOf("icon-") > -1;
  }
}
