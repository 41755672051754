import {  Component, Input } from '@angular/core';

@Component({
  selector: 'app-subproduct',
  templateUrl: './subproduct.component.html',
  styleUrls: ['./subproduct.component.scss'],
})

export class SubproductComponent {

 @Input('products')
  set _products(value: any[]) {
    this.products = [...value];
  }
  get _products(): any[] {
    return this.products;
  }
  
  private products: any[] = [];

  constructor(){}

  update(subproducts:any[]):void{
    this._products=[...subproducts];
  }
  
  getProducts():any{
    let product=this._products.map(p => {
      return {id: p.id, sub_products: (p.sub_products==null?"":p.sub_products)};
    });
    return (product==null || product.length==0 ? [] : product);
  }

}

