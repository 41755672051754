import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from './services/local-storage.service';
import { checkLink, PROFILELINKS, HOMELINKS, DASHBOARDLINKS } from './shared/permissions/permissions';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const role = this.localStorageService.getUserInfo().role;
    if (!role && state.url !== '/contact/message') {
      this.localStorageService.deleteAuthToken();
      this.redirectToLogin(state.url);
      return false;
    } else {
      if (checkLink(state.url, role)) {
        if (state.url === '/profile') {
          this.router.navigate([PROFILELINKS[role]]);
        } else if (state.url === '/home') {
          this.router.navigate([HOMELINKS[role]]);
        } else if (state.url === '/dashboard') {
          this.router.navigate([DASHBOARDLINKS[role]]);
        } 
        return true;
      } else {
        this.redirectTo403();
      }
    }
    return true;
  }

  private redirectToLogin(url:any): void {
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: url
      }
    });
    //this.router.navigate(['login']);
  }

  private redirectTo403(): void {
    this.router.navigate(['403']);
  }
}
