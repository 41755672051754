import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './shared/components/main-layout/main-layout.component';
import { AuthGuard } from './auth.guard';
import { ErrorComponent } from './components/error/error.component';
import { RoleGuard } from './role.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'home',
        children: [],
        canActivate: [AuthGuard, RoleGuard],
      },
      {
        path: 'profile',
        children: [],
        canActivate: [AuthGuard, RoleGuard],
      },
      
      {
        path: 'members',
        loadChildren: './members/members.module#MembersModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'suppliers',
        loadChildren: './suppliers/suppliers.module#SuppliersModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'messages',
        loadChildren: './messages/messages.module#MessagesModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'opportunities',
        loadChildren:
          './opportunities/opportunities.module#OpportunitiesModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'contact',
        loadChildren: './contact/contact.module#ContactModule'
      },
      {
        path: 'rate',
        loadChildren: './rate/rate.module#RateModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'searches',
        loadChildren: './searches/searches.module#SearchesModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'suppliers-rate',
        loadChildren: './suppliers-rate/suppliers-rate.module#SuppliersRateModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'rates',
        loadChildren: './suppliers-rates/rates.module#RatesModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'pending-changes',
        loadChildren: './pending/pending.module#PendingModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'about',
        loadChildren: './about/about.module#AboutModule'
      },
      {
        path: 'invitation/:hash',
        loadChildren: './invitation/invitation.module#InvitationModule'
      },
      {
        path: 'calendar-event',
        loadChildren: './calendar/calendar.module#CalendarModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'ticket',
        loadChildren: './ticket/ticket.module#TicketModule',
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'terms',
        loadChildren: './terms/terms.module#TermsModule'
      },
      {
        path: 'faqs',
        loadChildren: './faq/faq.module#FaqModule',
        canActivate: [RoleGuard]
      },
      { path: '404', component: ErrorComponent },
      { path: '403', component: ErrorComponent }
    ]
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
