import { Component } from '@angular/core';
import { ActiveModal } from '../../services/modal.service';

@Component({
    selector: 'app-accept',
    templateUrl: './accept.component.html',
    styleUrls: ['./accept.component.scss']
  })


  export class AcceptComponent {

    private downDocument: any;
    constructor(private activeModal: ActiveModal) { 
      if(activeModal.data && activeModal.data.uploadFile) {
        this.downDocument = this.activeModal.data.uploadFile; 
      }
      
    }

    close(reason: string): void {
      if(reason == "OK") {
      //download file
      this.downloadFile();
      this.activeModal.close(reason);
      } else {
        this.activeModal.close(reason);
      }
    }

    downloadFile() {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.downDocument);
      link.setAttribute('download', this.downDocument);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
