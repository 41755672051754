import { Component, OnDestroy } from '@angular/core';
import { LocalStorageService, CommonService } from '@services';
import { checkLink } from '../../permissions/permissions';
import { interval, Subject, Observable } from 'rxjs';
import { takeUntil, concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {

  _destroy$ = new Subject<void>();
  count: Observable<number>;
  contactCount: Observable<number>;
  userRole = null;


  constructor(private localStorageService: LocalStorageService, private sidebarService: CommonService) {
    const role = this.localStorageService.getUserInfo().role;
    this.userRole = role;
    if (role === 'supplier') {
      this.count = interval(5000).pipe(takeUntil(this._destroy$), concatMap(()  => this.sidebarService.getCount()));
    } else if (role === 'admin') {
      this.contactCount = interval(5001).pipe(takeUntil(this._destroy$), concatMap(()  => this.sidebarService.getContactCount()));
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  canShow(url: string): boolean {
    const role = this.localStorageService.getUserInfo().role;
    if (role) {
      return checkLink(url, role);
    }
    return false;
  }

  canShowProudly(): boolean {
    const proudly = this.localStorageService.getUserInfo().proudly;
    return (proudly != '');
  }

  get rate(): boolean {
    return this.localStorageService.canRate();
  }

  
}
