import { Component } from '@angular/core';
import { ActiveModal } from '@shared/services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  constructor(private activeModal: ActiveModal) {}

  close(reason: string): void {
    this.activeModal.close(reason);
  }

}
