import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit {

  @Input() filterOptions: {[key: string]: string[]} = {};
  @Output() apply = new EventEmitter();

  form: FormGroup;

  get keys(): string[] {
    return Object.keys(this.filterOptions);
  }

  ngOnInit(): void {
    this.form = this.toFormGroup();
  }

  onApply(): void {
    const value = this.form.value;
    Object.keys(value).forEach(key => {
      if (value[key] == null) {
        delete value[key];
      }
    });
    this.apply.emit(this.form.value);
  }

  toFormGroup() {
    const group: any = {};
    this.keys.forEach(key => {
      group[key] = new FormControl(null);
    });
    return new FormGroup(group);
  }

  isArray(value: any[]): boolean {
    return Array.isArray(value);
  }

}
