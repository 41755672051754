import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  error: string;

  constructor(private router: ActivatedRoute,
) {
  this.router.url.subscribe(url => {
    this.error = url.length ? url[0].path : null;
    }); }
}
