import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class CommonService {
  private readonly url = {
    pending: 'connect/pending',
    contactCount: 'contact/count'
  };

  constructor(private http: HttpClient) {}

  getCount(): Observable<number> {
    const headers = {hideSpinner: ''};
    return this.http.get<any>(this.url.pending, {headers}).pipe(map(res => res.pending));
  }

  getContactCount(): Observable<number> {
    const headers = {hideSpinner: ''};
    return this.http.get<any>(this.url.contactCount, {headers}).pipe(map(res => res.totalPending));
  }

}
