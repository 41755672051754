import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from './services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const authToken = this.localStorageService.getAuthToken();
    if (!authToken) {
      this.redirectToLogin(state.url);
      return false;
    }
    return true;
  }

  private redirectToLogin(url:any): void {
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: url
      }
    });
  }
}
