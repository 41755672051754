import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-experience',
    templateUrl: './experience.component.html',
    styleUrls: ['./experience.component.scss']
  })
  export class ExperienceComponent {
    @Input()
    form: FormGroup;

    toggleOptions = [
        { label: 'Does not meet', value: 1 },
        { label: 'Barely meets', value: 2 },
        { label: 'Meets Min. Expectations', value: 3 },
        { label: 'Meets most', value: 4 },
        { label: 'Fully meets', value: 5 }
      ];

  }
