import { Component } from '@angular/core';
import { LocalStorageService } from '@services';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  authToken: string;
  options = [];

  constructor(private localStorageService: LocalStorageService, private router: ActivatedRoute,
              private route: Router, ) {
    this.authToken = this.localStorageService.getAuthToken();
    this.route.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.router.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data.options) {
            return child.snapshot.data.options;
          } else {
            return null;
          }
        }
        return null;
      })
    )
    .subscribe(options => {
      if (options && options.length) {
        this.options = [...options];
      } else {
        this.options = [];
      }
    });
  }


}
