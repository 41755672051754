import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  Renderer2,
  ComponentRef
} from '@angular/core';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { SpinnerComponent } from '../components/spinner/spinner';
import { take } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSpinnerPortal: ComponentPortal<SpinnerComponent>;
  private renderer: Renderer2;
  private componentRef: ComponentRef<SpinnerComponent>;
  private classes: string[] = [];
  private activeLoaders = new Map<Element, DomPortalHost>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.loadingSpinnerPortal = new ComponentPortal(SpinnerComponent);
  }

  showLoader(): SpinnerComponent {
    const host = document.body;
    if (!this.activeLoaders.get(host)) {
      const portalHost = this.createHost(host);
      let loader: SpinnerComponent;
      loader = this.showLoadingSpinner(portalHost);
      this.activeLoaders.set(host, portalHost);
      return loader;
    }
    return null;
  }

  hide() {
    if (this.activeLoaders.size > 1) {
      throw new Error(
        'There are more than 1 active loading spinners. You must ' +
          'use the "hide()" method on the loader instance instead.'
      );
    }

    const firstActiveLoaderHost = this.activeLoaders.values().next().value;
    if (!firstActiveLoaderHost) {
      return;
    }
    this.hideWithPortalHost(firstActiveLoaderHost);
  }

  private showLoadingSpinner(portalHost: DomPortalHost): SpinnerComponent {
    this.componentRef = portalHost.attachComponentPortal(
      this.loadingSpinnerPortal
    );
    const instance = <SpinnerComponent> this.componentRef.instance;
    instance.cancel.pipe(take(1)).subscribe(() => {
      this.hideWithPortalHost(portalHost);
    });
    this.renderer = this.componentRef.instance.renderer;
    this.classes = ['body-with-spinner'];
    this.classes.push('body-with-spinner--spin');
    this.classes.push('body-with-spinner--blur');

    this.classes.forEach( el => {
      this.renderer.addClass(document.body, el);
    });
    return instance;
  }

  private createHost(host: Element): DomPortalHost {
    return new DomPortalHost(
      host,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }

  private hideWithPortalHost(portalHost: DomPortalHost) {
    this.classes.forEach(el => {
      this.renderer.removeClass(portalHost.outletElement, el);
    });
    portalHost.detach();
    this.activeLoaders.delete(portalHost.outletElement);
  }
}
